export const primary = '#FFFFFF';
export const primary2 = '#ecf4f9';
export const primary3 = '#323232';
export const primary35 = '#323232';
export const primary4 = '#323232';
export const primary45 = '#2FDD92';
export const primary46 = '#B3DDB7';

export const primary15 = '#323232';

export const primary5 = '#1B262C';
export const primary6 = '#132b3a';
export const primary7 = '#1C7947';

// export const primary = '#666';
// export const primary2 = '#EEE';
// export const primary3 = '#CCC';
// export const primary4 = '#AAA';
// export const primary5 = '#888';
// export const primary6 = '#333';

export const primary16 = 'hsl(208 100% 96% / 1)';

export const errorColor = '#ef6565';
export const lightErrorColor = '#ef9c9c';
export const goodColor = '#53c171';

export const cleanBorder = '1px solid rgb(208, 227, 239)';
export const lightBorder = '#B3DDB7';
